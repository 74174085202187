import request from '../request'

export function fetchList(params) {
    return request({
        url: '/user-service/permission/list',
        params
    })
}

export function add(data) {
    return request({
        url: '/user-service/permission/add',
        method: 'POST',
        data,
    })
}

export function fetchDetail(params) {
    return request({
        url: '/user-service/permission/get',
        params
    })
}

export function edit(data) {
    return request({
        url: '/user-service/permission/update',
        method: 'POST',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/user-service/permission/del',
        method: 'POST',
        data
    })
}

export function fetchUserPermissionList(params) {
    return request({
        url: `/user-service/permission/byuser?uuid=${params.uuid}`
    })
}
